import { computed, ref } from 'vue'

import { choose } from './pluralization'

import type { Plugin, ComputedRef } from 'vue'

const messageFiles: Record<
  string,
  {
    default: MessagesInterface
  }
> = import.meta.glob('../../../lang/*.json', {
  eager: true,
})

type MessagesInterface = Record<string, string>
type ReplacementsInterface = Record<string, string | number | null | undefined>

export const definedLanguages = {
  de: 'Deutsch',
} satisfies Record<string, string>

const fallbackLanguage = 'de' satisfies keyof typeof definedLanguages

const isServer = typeof window === 'undefined'

/**
 * Stores the current options.
 */
const activeLanguage = ref(getDefaultLanguage())

const messageOverrides = ref<Record<string, MessagesInterface>>({})

/**
 * The active messages to use.
 */
const activeMessages = ref<MessagesInterface>({})

function capitalize(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

function getDefaultLanguage(): string {
  return !isServer && document.documentElement.lang
    ? document.documentElement.lang.replace('-', '_')
    : fallbackLanguage
}

/**
 * Get the translation for the given key.
 */
export function trans(
  key: string | null | undefined,
  replacements?: ReplacementsInterface,
): string {
  if (key == null) {
    return ''
  }

  return makeReplacements(activeMessages.value[key] ?? key, replacements)
}

/**
 * Get the translation for the given key and watch for any changes.
 */
export function wTrans(key: string, replacements?: ReplacementsInterface): ComputedRef<string> {
  return computed(() => makeReplacements(activeMessages.value[key] ?? key, replacements))
}

/**
 * Translates the given message based on a count.
 */
export function transChoice(
  key: string,
  number: number,
  replacements: ReplacementsInterface = {},
): string {
  return wTransChoice(key, number, replacements).value
}

/**
 * Translates the given message based on a count and watch for changes.
 */
export function wTransChoice(
  key: string,
  number: number,
  replacements: ReplacementsInterface = {},
): ComputedRef<string> {
  const message = wTrans(key, replacements)

  replacements.count = number.toString()

  return computed(() =>
    makeReplacements(choose(message.value, number, activeLanguage.value), replacements),
  )
}

/**
 * Returns the current active language.
 */
export function getActiveLanguage(): string {
  return activeLanguage.value
}

export function setMessageOverrides(overrides: Record<string, MessagesInterface>) {
  messageOverrides.value = overrides
}

export function setLanguage(lang = activeLanguage.value) {
  if (!isServer) {
    // When setting the HTML lang attribute, hyphen must be use instead of underscore.
    document.documentElement.setAttribute('lang', lang.replace('_', '-'))
  }

  if (!(lang in definedLanguages)) {
    throw new Error(`The language code ${lang} is not supported.`)
  }

  const messages: MessagesInterface = messageFiles[`../../../lang/${lang}.json`].default

  // apply overrides
  const overrides = messageOverrides.value[lang] ?? {}

  for (const key in overrides) {
    if (key in messages) {
      messages[key] = overrides[key]
    }
  }

  activeLanguage.value = lang
  activeMessages.value = messages
}

/**
 * Make the place-holder replacements on a line.
 */
function makeReplacements(message: string, replacements?: ReplacementsInterface): string {
  if (!replacements) {
    return message
  }

  for (const [key, value] of Object.entries(replacements)) {
    const stringValue = value?.toString() ?? ''

    message = message
      .replace(`:${key}`, stringValue)
      .replace(`:${key.toUpperCase()}`, stringValue.toUpperCase())
      .replace(`:${capitalize(key)}`, capitalize(stringValue))
  }

  return message
}

/**
 * The Vue Plugin. to be used on your Vue app like this: `app.use(i18nVue)`
 */
export const i18nVue: Plugin<{ messageOverrides?: Record<string, MessagesInterface> }> = {
  install: (app, options) => {
    messageOverrides.value = options.messageOverrides ?? {}

    app.config.globalProperties.$t = (
      key: string | null | undefined,
      replacements?: ReplacementsInterface,
    ) => trans(key, replacements)
    app.config.globalProperties.$tChoice = (
      key: string,
      number: number,
      replacements?: ReplacementsInterface,
    ) => transChoice(key, number, replacements)

    setLanguage(activeLanguage.value)
  },
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $t: typeof trans
    $tChoice: typeof transChoice
  }
}
