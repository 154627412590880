import '@clickbar/tailwindui-vue/style.css'
import '@fontsource-variable/inter'
import { createInertiaApp, Link } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { createApp, h } from 'vue'

import '../css/app.css'
import sentryVuePlugin from '@/app/sentry'
import { i18nVue } from '@/plugins/i18n'

import type { DefineComponent } from 'vue'

const appName = window.document.querySelectorAll('title')[0].textContent ?? 'Mobilityportal'

void createInertiaApp({
  title: (title: string) => `${title} - ${appName}`,
  progress: {
    color: '#4B5563'
  },

  resolve: (name: string) =>
    resolvePageComponent(
      `../pages/${name}.vue`,
      import.meta.glob<DefineComponent>('../pages/**/*.vue')
    ),

  setup({ el, App, props, plugin }) {

    const tenant = props.initialPage.props.tenant as App.Data.Tenant.PublicTenantData | undefined

    createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(sentryVuePlugin)
      .use(i18nVue, {
        messageOverrides: tenant?.message_overrides ?? {}
      })
      .component('inertia-link', Link)
      .component('InertiaLink', Link)
      .mount(el)
  }
})

//@ts-expect-error check how to fix
window.mediaLibraryTranslations = {
  name: 'Name',
  fileTypeNotAllowed: 'Dieser Dateityp ist nicht erlaubt',
  tooLarge: 'Datei zu groß, max',
  tooSmall: 'Datei zu klein, min',
  tryAgain: 'bitte versuchen Sie es erneut',
  somethingWentWrong: 'Beim Hochladen ist ein Fehler aufgetreten',
  selectOrDrag: 'Datei(en) auswählen oder hier ablegen',
  selectOrDragMax: 'Maximal {maxItems} {file} auswählen oder hier ablegen',
  file: { singular: 'Datei', plural: 'Dateien' },
  anyImage: 'Bild',
  anyVideo: 'Video',
  goBack: 'Zurück',
  dropFile: 'Datei zum hochladen hier ablegen',
  dragHere: 'Datei hier ablegen',
  remove: 'Entfernen',
  download: 'Download'
}
